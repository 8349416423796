<template>
  <div>
    <PageHeader>
      <template slot="right">
        <div>
          <b-button
              v-if="$allowPermission('agent:manage.announce')"
              to="/announce/create"
              variant="primary"
          >
            <i class="uil-plus mr-1"></i>
            {{ $t('buttons.add') }}
          </b-button>
        </div>
      </template>
    </PageHeader>

    <div class="card">
      <div class="card-body">
        <h6>
          {{ `${$t('fields.result')} (${total})` }}
        </h6>
        <b-row
            align-v="center"
            class="mb-3"
        >
          <b-col
              cols="12"
              md="3"
          >
            <LimitSelect v-model="selectedLimit"/>
          </b-col>
          <b-col
              cols="12"
              md="9"
          >
            <b-row>
              <b-col cols="5">
                <b-input-group>
                  <b-form-input
                      v-model="search"
                      :placeholder="`${$t('fields.search')}... `"
                      class="mx-1"
                      type="search"
                  ></b-form-input>
                </b-input-group>
              </b-col>
              <b-col>
                <MasterSelectInput
                    :value="selectedMaster"
                    hide-label
                    @update="onMasterIdChange"
                />
              </b-col>
              <b-col>
                <AgentsSelectInput
                    :master-id="selectedMaster"
                    :value="selectedAgent"
                    hide-label
                    @update="onAgentIdChange"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-table
            :busy="isFetching"
            :fields="fields"
            :items="announceList"
            responsive
            show-empty
        >
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(agent)="data">
            <template v-if="data.value">
              {{ data.value.name }}
            </template>
            <span v-else class="badge badge-info">
              ทุกเอเย่นต์
            </span>
          </template>
          <template #cell(isClose)="data">
            <span v-if="announceStatus(data.item) === 'pause'" class="badge badge-danger">
               พักชั่วคราว
            </span>
            <span v-else-if="announceStatus(data.item) === 'waiting'" class="badge badge-secondary">
              เปิดใช้งาน
            </span>
            <span v-else-if="announceStatus(data.item) === 'running'" class="badge badge-success">
              เปิดใช้งาน
            </span>
            <span v-else class="badge badge-danger">
              หมดเวลา
            </span>
          </template>
          <template #cell(startDateTime)="data">
            <span class="badge">ตั้งแต่</span> {{ data.value | datetime }}
            <div v-if="data.item.endDateTime">
              <span class="badge">ถึง</span> {{ data.item.endDateTime | datetime }}
            </div>
          </template>
          <template #cell(userAgent)="data">
            {{ data.value.name }}
          </template>
          <template #cell(createdAt)="data">
            {{ data.value | datetime }}
          </template>
          <template #cell(id)="data">
            <b-dropdown
                v-if="$allowPermission('agent:manage.announce')"
                dropleft
                size="sm"
                variant="info"
            >
              <template #button-content>
                <i class="uil uil-cog"></i>
              </template>
              <b-dropdown-item :to="`/announce/detail/${data.item.id}`">
                <i class="uil uil-edit-alt mr-2"></i>
                {{ $t('buttons.edit')}}
              </b-dropdown-item>
              <b-dropdown-item
                  :variant="data.item.isClose ? 'info' : 'warning'"
                  @click="toggleStatus(data.item)"
              >
                <i class="uil uil-pause-circle mr-2"></i>
                <template v-if="data.item.isClose">
                  แสดงประกาศ
                </template>
                <template v-else>
                  พักชั่วคราว
                </template>
              </b-dropdown-item>
              <b-dropdown-item
                  variant="danger"
                  @click="onDelete(data.value)"
              >
                <i class="uil uil-trash-alt mr-2"></i>
                <span>
                  {{ $t('buttons.remove')}}
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template #empty="">
            <p class="text-center text-muted">{{ $t('messages.nothing_here')}}</p>
          </template>
        </b-table>

        <PaginationInput
            :per-page="limit"
            :total="total"
            @update="(val) => currentPage = val"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import dayjs from 'dayjs';
import * as isBetween from 'dayjs/plugin/isBetween'

export default {
  page: {
    title: 'ประกาศ',
  },
  components: {
    MasterSelectInput: () => import('@components/master/master-select-input'),
    AgentsSelectInput: () => import('@components/agents/agents-select-input'),
  },
  data() {
    return {
      currentPage: 1,
      selectedLimit: 20,
      selectedAgent: '',
      selectedMaster: '',
      search: '',
      isFetching: false,
      fields: [
        '#',
        { key: 'title', label: 'หัวข้อ' },
        { key: 'startDateTime', label: 'เวลาที่แสดง' },
        { key: 'isClose', label: 'สถานะ' },
        { key: 'userAgent', label: 'คนสร้าง' },
        { key: 'createdAt', label: 'สร้างเมื่อ' },
        {
          key: 'id',
          label: '',
          class: 'text-right',
        },
      ],
      onSearchTimeout: null,
    }
  },
  computed: {
    ...mapGetters(['announces', 'isAgent']),
    announceList() {
      const { items } = this.announces
      return items || []
    },
    pagination() {
      return this.announces.meta
    },
    limit() {
      return this.pagination.itemsPerPage
    },
    total() {
      return this.pagination.totalItems
    },
  },
  watch: {
    currentPage(val) {
      if (val) {
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
    search() {
      this.delaySearch()
    },
    selectedAgent() {
      this.fetchData()
    },
    selectedMaster() {
      this.fetchData()
    },
    selectedLimit(val) {
      if(val) {
        this.currentPage > 1 ? this.currentPage = 1 : this.fetchData()
      }
    },
  },
  created() {
    this.fetchData()
  },
  mounted() {
    if(!this.isAgent) {
      this.fields.splice(1, 0, { key: 'agent', label: 'เอเย่นต์' })
    }
  },
  methods: {
    ...mapActions([
      'fetchAnnounces',
      'updateAnnounce',
      'deleteAnnounce'
    ]),
    async onDelete(announceId) {
      this.isFetching = true
      await this.deleteAnnounce(announceId)
      await this.fetchData()
    },
    async toggleStatus(announce) {
      this.isFetching = true
      await this.updateAnnounce({
        id: announce.id,
        data: {
          isClose: !announce.isClose,
          startDateTime: announce.startDateTime,
          endDateTime: announce.endDateTime
        }
      })
      await this.fetchData()
    },
    async fetchData() {
      this.isFetching = true
      await this.fetchAnnounces({
        limit: this.selectedLimit,
        page: this.currentPage,
        search: this.search,
        agentId: this.selectedAgent,
        masterId: this.selectedMaster,
      })
      this.isFetching = false
    },
    announceStatus(announce) {
      dayjs.extend(isBetween)
      if(announce.isClose) {
        return 'pause'
      } else if(dayjs().isBefore(announce.startDateTime)) {
        return 'waiting'
      } else if(dayjs().isBetween(announce.startDateTime, announce.endDateTime || dayjs().add(1, 'days'))) {
        return 'running'
      } else {
        return 'expired'
      }
    },
    delaySearch() {
      clearTimeout(this.onSearchTimeout)
      this.onSearchTimeout = setTimeout(this.fetchData, 800)
    },
    onAgentIdChange(agentId) {
      this.selectedAgent = agentId
    },
    onMasterIdChange(masterId) {
      this.selectedMaster = masterId
    },
  }
}
</script>

<style scoped>

</style>